#creative-page {
  padding-top: 60px;
  background-color: #fff;
  min-height: 100vh;
	.heading-wrap {
		display: flex;
		align-items: flex-end;
		border-bottom: 1px solid $black;
		margin-bottom: 20px;
		padding-bottom: 12px;
    .site-name {
      font-size: 16px;
      margin: 0;
    }
    p {
      font-size: 24px;
      justify-self: flex-end;
    }
    img.mbv-icon {
      width: 18px;
      height: 18px;
      margin: 1px 8px 0 0;
    }
    img.flag {
      width: auto;
      height: 15px;
      margin-top: 3px;
      box-shadow: 2px 1px 6px $gray-dk;
    }
  }
  .subheading {
    font-family: $font-subheading;
    font-size: 26px;
  }
  .subheading-2 {
    font-family: $font-aldine-italic;
    color: $gray-60;
    font-size: 20px;
  }
  .list-heading {
    font-family: $font-main-semibold;
    font-size: 22px;
    margin-bottom: 20px;
  }
  [class*='column-'] {
    width: 300px;
  }
	.wrap {
    display: flex;
    width: 100%;
		> * {
			flex: 1 1 50%;
		}
	}
	.flex-column {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	a {
		position: relative;
		display: inline-block;
		font-family: $font-main;
		font-size: 18px;
		color: $black;
		margin-bottom: 12px;
		&:after {
			content: '';
			position: absolute;
      bottom: auto;
      left: auto;
      width: auto;
      height: 0;
      transform: translateX(5px);
		}
		&:hover {
      text-decoration: none;
			font-family: $font-main-semibold;
			&:after {
				content: '\027A4';
			}
		}
	}
	.section {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		border-bottom: 1px solid $gray-20;
		margin-bottom: 12px;
		&:last-of-type {
			border-bottom: none;
		}
	}
	.gray {
    color: $gray-60;
  }
}

#splash-page-ie {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $bonvoy-white;
  overflow-y: scroll;
  z-index: 99999;
  .wrap {
    margin: 100px auto 0;
    padding-bottom: 50px;
    max-width: 800px;
    img {
      width: 140px;
    }
    .browsers {
      display: flex;
      justify-content: center;
    }
    .browser {
      padding: 0 30px;
      text-align: center;
      a {
        text-align: center;
        color: #000;
      }
      img {
        width: auto;
        height: 50px;
        margin-bottom: 10px;
      }
      .link-arrow {
        display: block;
      }
    }
  }
  @media #{$mxw-sm} {
    .wrap {
      margin: 40px auto 0;
    }
    .browsers {
      flex-direction: column;
      align-items: center;
    }
    .browser {
      margin-bottom: 45px;
      &:last-of-type {
        margin-bottom: 0;
      }
      img {
        height: 35px;
      }
      .link-arrow {
        display: inline-block;
      }
    }
  }
}
