// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// GLOBAL UTILITY
$px: .0625rem;
$page-max-width: 1440px;
$imgPath: '../images/';

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// FONTS
$font-aldine-light: 'Aldine Light', Times, serif;
$font-aldine-italic: 'Aldine Light Italic', Times, serif;
$font-swiss-light: 'Swiss Light', Helvetica, Arial, sans-serif;
$font-swiss-regular: 'Swiss Regular', Helvetica, Arial, sans-serif;
$font-swiss-medium: 'Swiss Medium', Helvetica, Arial, sans-serif;
$font-swiss-bold: 'Swiss Bold', Helvetica, Arial, sans-serif;
$font-swiss-italic: 'Swiss Italic', Helvetica, Arial, sans-serif;
$font-proxima-regular: 'ProximaNova-Regular', Helvetica, Arial, sans-serif;

$font-main: $font-swiss-light;
$font-main-semibold: $font-swiss-regular;
$font-main-bold: $font-swiss-medium;
$font-heading: $font-aldine-light;
$font-subheading: $font-aldine-light;
$font-btn: $font-swiss-regular;
$font-alt: $font-aldine-italic;

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// COLORS
$black: #1c1c1c;
$gray: #979797;
$gray-dk: #707070;
$gray-lt: #bbb;
$gray-90: darken(#fff, 90%);
$gray-80: darken(#fff, 80%);
$gray-70: darken(#fff, 70%);
$gray-60: darken(#fff, 60%);
$gray-50: darken(#fff, 50%);
$gray-40: darken(#fff, 40%);
$gray-30: darken(#fff, 30%);
$gray-20: darken(#fff, 20%);
$gray-10: darken(#fff, 10%);
$gray-5: darken(#fff, 5%);
$color-1: #cedcdf;
$color-1-hov: #696676;
$color-2: #ffdaa0;
$coral: #ff9662;
$coral-lt: #ffb995;
$coral-xlt: #ffebe0;
$bonvoy: #b84c16;
$bonvoy-white: #f9f6f4;
$color-main: $gray-80;
$color-error: #d30000;
$color-error-lt: #ffe2e2;
$color-success: #208036;
$color-success-lt: #e3ffe9;
$gold: #8c6f49;
$platinum: #5e5f61;
$titanium: #554749;

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// BREAKPOINTS
$bp-xl: 1200px;
$bp-lg: 992px;
$bp-md: 768px;
$bp-sm: 576px;
$bp-xs: 420px;
$bp-xxs: 370px;

$bp-page-nav: 1025px;
$bp-mob-lg: 480px;

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// MEDIA QUERIES
$mxw-xl: '(max-width: #{$bp-xl - 1})';
$mxw-lg: '(max-width: #{$bp-lg - 1})';
$mxw-md: '(max-width: #{$bp-md - 1})';
$mxw-sm: '(max-width: #{$bp-sm - 1})';
$mxw-xs: '(max-width: #{$bp-xs - 1})';
$mxw-xxs: '(max-width: #{$bp-xxs - 1})';

$mxw-page-nav: '(max-width: #{$bp-page-nav - 1})';
$mxw-social-share-modal: $mxw-md;

$mnw-xxs: '(min-width: #{$bp-xxs})';
$mnw-xs: '(min-width: #{$bp-xs})';
$mnw-sm: '(min-width: #{$bp-sm})';
$mnw-md: '(min-width: #{$bp-md})';
$mnw-lg: '(min-width: #{$bp-lg})';
$mnw-xl: '(min-width: #{$bp-xl})';

$mnw-page-nav: '(min-width: #{$bp-page-nav})';

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// BORDERS
$bd-w: 1px;
$bd-black: $bd-w solid $black;
$bd-gray: $bd-w solid $gray;
$bd-gray-dk: $bd-w solid $gray-dk;
$bd-gray-lt: $bd-w solid $gray-lt;
$bd-rad-1: 19px;
$input-bd-rad: 6px;
$input-bd-color: #c1c1c1;
$box-shadow: 3px 3px 4px rgba(#000, 0.2);
$box-shadow-2: 0 1px 9px rgba(#000, 0.25);
$box-shadow-2-hov: 0 1px 5px rgba(#000, 0.3);
$inset-border-distance: 8px;

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// APP SPECIFIC
$radio-btn-size: 20px;
$radio-btn-color: $black;
$boutiques-swiper-bp: 600px;

:root {
  --color-shimmer: silver;
  --shimmer: shimmer 3s infinite;
  --shimmer-angle: 120deg;
  --shimmer-bg-pos: 0vw;

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  // FONTS
  --font-main: #{$font-main};
  --font-main-semibold: #{$font-main-semibold};
  --font-main-bold: #{$font-main-bold};
  --font-heading: #{$font-heading};
  --font-subheading: #{$font-subheading};
  --font-btn: #{$font-btn};
  --font-alt: #{$font-alt};

  --font-aldine-light: #{$font-aldine-light};
  --font-aldine-italic: #{$font-aldine-italic};
  --font-swiss-light: #{$font-swiss-light};
  --font-swiss-regular: #{$font-swiss-regular};
  --font-swiss-medium: #{$font-swiss-medium};
  --font-swiss-bold: #{$font-swiss-bold};
  --font-swiss-italic: #{$font-swiss-italic};
  --font-proxima-regular: #{$font-proxima-regular};

  --number-animation-duration: 2s;
  --flip-circle-size: 85px;
  --circled-number-size: 27px;

  --most-visited-icon-sz: 121px;
  --social-share-border-radius: min(1.1875em, 19px);
  --social-share-bg-h: 783px;
}
@media #{$mxw-md} {
  :root {
    --shimmer: shimmer 2s infinite;
  }
}
