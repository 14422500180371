@import 'fonts';
@import 'variables';
@import 'mixins';

// Vendor
@import '~bootstrap/scss/bootstrap';
@import '~spin.js/spin.css';

html,
body {
  overflow-x: hidden;
}

#app {
  font-family: $font-main;
  font-size: 18px;
  line-height: 1.375;
  @import 'utilities';
  @import 'base';
  @import 'page';
  @import 'odometer';
  @import 'page_global';
}

header.global-header.universal-header {
  .header-logo {
    @media #{$mnw-md} {
      height: 8rem;
    }
  }
  &.no-shadow {
    box-shadow: none;
    .header-logo {
      box-shadow: none;
    }
  }
  .navbar-toggler {
    color: $black;
  }
  .subnav .signed-in {
    padding: .8rem;
    .profile-image {
      width: 18px;
    }
  }
}
