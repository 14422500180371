#landing-page {
  .hero {
    background-image: url('#{$imgPath}hero_d.jpg');
    .heading {
      padding-top: 50px;
    }
    @media #{$mxw-md} {
      background-image: url('#{$imgPath}hero_m.jpg');
      .heading {
        font-size: 32px;
        max-width: 60vw;
        margin: 0 auto;
      }
    }
    @media #{$mxw-sm} {
      height: 414px;
      .heading {
        max-width: unset;
      }
    }
  }
  .body {
    font-size: 20px;
    @media #{$mxw-sm} {
      font-size: 18px;
    }
  }
}
.misc-page {
  .hero {
    background-repeat: no-repeat;
    background-position: 95% 100%;
    background-size: cover;
    height: 440px;
  }
  .body {
    @media #{$mxw-md} {
      font-size: 16px;
    }
  }
}

#celebrate-page {
  .hero {
    background-image: url('#{$imgPath}hero-celebrate_d.jpg');
    margin-bottom: 50px;
    @media #{$mxw-md} {
      background-image: url('#{$imgPath}hero-celebrate_m.jpg');
      margin-bottom: 30px;
    }
  }

  .question {
    font-family: $font-main-bold;
    margin: 50px 0 20px;
  }

  .question-1 {
    .flex-grid {
      @media #{$mnw-md} {
        @include flex-grid(3, 30px);
      }
      @media #{$mxw-md} {
        display: flex;
        flex-direction: column;
        > * {
          margin-bottom: 20px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
    img {
      width: 100px;
      margin-right: 16px;
    }
    label {
      @media #{$mnw-md} and #{$mxw-lg} {
        font-size: 16px;
      }
    }
  }

  .checkbox {
    label {
      margin-top: 7px;
    }
  }

  .region-sections {
    .flex-grid {
      @include flex-grid(3, 23px);
      a {
        color: $black;
      }
    }
    img {
      border-radius: 18px;
    }
  }

  .swiper .swiper-slide {
    max-width: 310px;
    width: auto;
    color: #1c1c1c;
    margin-right: 20px;
  }
}

// %%%%%%%%%%%%%%%%%%%%%%%
// START CELEBRATE LIFETIME PAGE
.video-1-wrap {
  @media #{$mxw-md} {
    max-width: 500px;
  }
}
.video-2-wrap {
  position: relative;
}
.custom-video-btns {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 35px 0 25px;
  opacity: 1;
  @include transition;
  @media #{$mxw-md} {
    padding: 0 15px;
  }
  #share-video-btn {
    width: 44px;
    margin-top: 10px;
    @media #{$mxw-md} {
      margin-top: 15px;
    }
    img {
      width: 27px;
      height: 21px;
      margin-bottom: 3px;
    }
  }
  &.hidden {
    opacity: 0;
  }
}

#modal-social-share {
  .modal-content {
    border-radius: 0;
  }
  .modal-dialog {
    max-width: 95vw;
    width: min(64.125em, 1026px);
    margin: 30px auto;
    @media #{$mxw-social-share-modal} {
      width: 100%;
    }
  }
  .modal-body {
    // padding: 30px 30px 50px 30px;
    padding: min(1.875em, 30px) min(1.875em, 30px) min(50*$px, 50px) min(1.875em, 30px);
  }
  .close-btn-v2 {
    display: flex;
    align-items: center;
    font-size: 21px;
    color: $bonvoy;
    &:hover {
      color: $black;
    }
    .close-x {
      font-size: 35px;
      transform: translateY(-2px);
    }
    @media #{$mxw-xl} {
      top: 5px;
      right: 10px;
    }
    @media (max-width: $bp-mob-lg) {
      font-size: 16px;
      .close-x {
        font-size: 28px;
      }
    }
  }
  .swiper-pagination-bullet {
    width: 24px;
    height: 24px;
    margin: 0 6px;
    @media (max-width: $bp-mob-lg) {
      width: 16px;
      height: 16px;
      margin: 0 5px;
    }
  }
} // #modal-social-share

#modal-share {
  .modal-dialog {
    @media #{$mnw-sm} {
      width: 380px;
    }
  }
  .copy-url {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border: 1px solid $black;
    border-radius: $input-bd-rad;
    padding: 5px 5px 5px 13px;
    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
    input {
      border: none;
      border-radius: 0;
      height: unset;
      padding: 0;
      outline: none;
    }
    button {
      border-radius: $input-bd-rad;
      padding: 6px 14px;
      font-size: 14px;
      border: 1px solid $black;
    }
    .btn-2-bd {
      cursor: default;
      &:hover {
        background-color: #fff;
      }
    }
  }
}

#section-member-since-ribbon {
  padding: 16px 0;
  .borders {
    border-top: 1px solid $bonvoy-white;
    border-bottom: 1px solid $bonvoy-white;
  }
  .icon-member-wrap {
    position: absolute;
    top: -31px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    img {
      width: 25px;
      height: 25px;
    }
  }
  .heading-caps {
    @media #{$mxw-md} {
      font-size: 14px;
    }
  }
}

.info-heading {
  font-family: $font-main-semibold;
  font-size: 14px;
}

#section-gift-opt {
  .gift-select {
    @media #{$mnw-md} {
      @include flex-grid(2, 30px);
    }
    @media #{$mxw-md} {
      display: flex;
      flex-direction: column-reverse;
      max-width: 500px;
      margin: 0 auto;
      > * {
        width: 100%;
      }
    }
    .info-wrap {
      max-width: 380px;
    }
  }
  .info-wrap {
    display: inline-block;
    font-size: 14px;
    @media #{$mxw-md} {
      max-width: unset;
    }
    p {
      margin-bottom: 0;
    }
  }
  .edit {
    position: absolute;
    top: 0;
    right: 0;
  }
  .note {
    max-width: 380px;
    padding: 10px;
    border: 1px solid $coral-lt;
    border-radius: 8px;
  }
  .gift-confirm {
    .flex-grid {
      max-width: 500px;
      margin: 0 auto;
      @media #{$mnw-xs} {
        @include flex-grid(2, 20px);
      }
      @media #{$mxw-sm} {
        width: 100%;
        > * {
          margin-bottom: 20px;
        }
      }
    }
  }
}

#modal-edit {
  .flex-grid {
    @media #{$mnw-md} {
      @include flex-grid(2, 20px);
    }
  }
  .section-gap {
    padding: 7px 0 20px;
    @media #{$mxw-md} {
      padding: 0;
    }
  }
  .input-wrap {
    @media #{$mxw-md} {
      padding: 7px 0 15px;
    }
  }
  input#zip {
    max-width: 100px;
    @media #{$mxw-md} {
      max-width: unset;
    }
  }
  select#state {
    background-position: 92% 50%;
  }
  select#code {
    background-position: 92% 50%;
    max-width: 300px;
  }
  select {
    padding-right: 28px;
  }
  .error .error-msg {
    &.address {
      width: 400px;
      @media #{$mxw-md} {
        position: relative;
        width: unset;
      }
    }
  }
}

#section-boutiques {
  position: relative;
  .swiper-outer-wrap {
    @media (max-width: $boutiques-swiper-bp) {
      overflow: visible;
    }
  }
  .swiper-boutiques {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    z-index: 11;
    @media (max-width: $boutiques-swiper-bp) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .swiper-slide {
    color: $black;
    overflow: hidden;
    border: 1px solid $gray-20;
    box-shadow: $box-shadow-2;
    margin-right: 20px;
    width: auto;
    @media (max-width: $boutiques-swiper-bp) {
      max-width: 305px;
    }
    &:hover {
      box-shadow: $box-shadow-2-hov;
    }
  }
  .copy-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 32px;
    background-color: #fff;
    @media #{$mxw-md} {
      padding: 15px 20px;
    }
    p {
      font-family: $font-aldine-light;
      max-width: 185px;
      padding-right: 20px;
    }
    img {
      width: 15px;
      height: 8px;
      transform: rotate(-90deg);
      margin-top: 4px;
    }
  }
  .nav-wrapper {
    position: absolute;
    top: -9px;
    left: 50%;
    transform: translateX(-50%);
    width: 205px;
    z-index: 11;
  }
  .swiper-pagination-boutiques {
    display: flex;
    justify-content: center;
    top: -17px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 0;
    z-index: 11;
  }

  .promo {
    position: relative;
    z-index: 10;
    @media #{$mxw-md} {
      font-size: 16px !important;
    }
  }

  .background {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 190px;
    @media (max-width: 1024px) {
      height: 214px;
    }
    @media (max-width: $boutiques-swiper-bp) {
      height: 194px;
    }
    @media #{$mxw-xs} {
      height: 216px;
    }
  }
}

.swiper-boutiques,
.swiper-redemption-highlights {
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
}

#modal-boutiques {
  li {
    padding-bottom: 16px;
  }
  .lower-alpha li {
    padding-bottom: 8px;
  }
}

#section-highlights {
  .highlights-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 20px;
    .grid-child {
      border-radius: $bd-rad-1;
    }
    .grid-child:not(.child-5) {
      position: relative;
      padding: 35px 20px;
      background-color: $color-1;
      @media #{$mxw-lg} {
        padding: 25px 20px;
      }
    }
    .grid-child.child-5 {
      grid-area: 2 / 1 / 3 / 4;
      background-image: url('#{$imgPath}bg_top-cities_d.jpg');
      background-position: 18% 100%;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 50px 40px;
    }
    .grid-child.child-6 { // Brand flip circles
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 27px 23px 10px;
    }
    .subheading {
      @media #{$mxw-lg} {
        font-size: 20px;
      }
    }
    .stats-value {
      font-size: 50px;
      @media #{$mxw-xl} {
        font-size: 40px;
      }
      @media #{$mxw-lg} {
        font-size: 28px;
      }
      @media #{$mxw-xxs} {
        font-size: 20px;
      }
    }
    .inset-border {
      position: absolute;
      top: $inset-border-distance;
      left: $inset-border-distance;
      width: calc(100% - (#{$inset-border-distance * 2}));
      height: calc(100% - (#{$inset-border-distance * 2}));
      border: 1px solid #fff;
      border-radius: $bd-rad-1 - 5px;
      pointer-events: none;
    }
    .top-cities {
      display: inline-block;
      width: 310px;
      background-color: #fff;
      padding: 22px 30px 35px 30px;
      border-radius: $bd-rad-1;
      li {
        font-size: 14px;
      }
    }
    .brands {
      display: flex;
      flex-direction: column;
      align-items: center;
      li:last-of-type .flipping-circle {
        margin-bottom: 0;
      }
      .flipping-circle {
        padding-bottom: 0;
        margin-bottom: 15px;
      }
      .face-front,
      .face-back {
        img {
          object-fit: contain;
        }
      }
      .face-front {
        img {
          max-width: 60px;
          max-height: 45px;
          @media #{$mxw-xxs} {
            max-width: 50px;
            max-height: 30px;
          }
        }
      }
      .face-back {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .back-wrap {
          position: relative;
          top: -2px;
          @media #{$mxw-xxs} {
            top: -4px;
          }
        }
        img {
          width: 50px;
          max-height: 26px;
          margin-bottom: 2px;
          @media #{$mxw-xxs} {
            width: 37px;
            max-height: 14px;
            margin-bottom: 0;
          }
        }
        .stays {
          font-family: $font-aldine-light;
          font-size: 26px;
          line-height: 1;
          @media #{$mxw-xxs} {
            font-size: 22px;
          }
        }
      }
      .four-points {
        .face-back img {
          max-width: 36px;
        }
      }
    }
    .instructions {
      p {
        font-family: $font-aldine-italic;
        font-size: 16px;
        line-height: 1;
        text-align: center;
      }
      .tap {
        display: none;
      }
      @media (pointer: coarse) {
        .click {
          display: none;
        }
        .tap {
          display: inline;
        }
      }
    }
    @media #{$mxw-md} {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto auto;
      max-width: 500px;
      margin: 0 auto;
      .grid-child.child-5 {
        grid-area: 3 / 1 / 4 / 3;
        background-position: 0 100%;
      }
      .grid-child.child-6 {
        grid-area: 4 / 1 / 5 / 3;
      }
      .top-cities {
        padding: 22px 20px 25px 20px;
      }
      .brands {
        flex-direction: row;
        margin-bottom: 10px;
        li {
          &:last-of-type .flipping-circle {
            margin: 0;
          }
          .flipping-circle {
            margin: 0 15px 0 0;
          }
        }
      }
    }
    @media #{$mxw-sm} {
      .grid-child.child-5 {
        background-position: 12% 100%;
      }
      .heading-caps {
        font-size: 14px;
      }
    }
    @media #{$mxw-xs} {
      .grid-child.child-5 {
        background-image: url('#{$imgPath}bg_top-cities_m.jpg');
        padding: 30px 40px;
      }
      .top-cities {
        max-width: unset;
        width: 100%;
        margin-bottom: 100px;
        padding: 20px 15px;
      }
    }
    @media #{$mxw-xxs} {
      .grid-child.child-5 {
        padding: 20px;
      }
      .heading-caps {
        font-size: 12px;
      }
    }
  } // .highlights-grid

  .redemption-highlights {
    .heading {
      font-size: 32px;
    }
    .swiper-container {
      @media #{$mnw-md} and #{$mxw-lg} {
        max-width: 992px;
      }
      @media #{$mxw-lg} {
        padding-right: 0;
        padding-left: 0;
      }
    }
    .swiper-outer-wrap {
      padding: 10px 0 60px;
    }
    .swiper-wrapper {
      height: 100%;
    }
    .swiper-slide {
      width: 790px;
      max-width: 790px;
      // width: auto;
      height: auto;
      margin: 0 10px;
      padding: 32px;
      box-shadow: $box-shadow-2;
    }
    img.main {
      width: 154px;
    }
    .logo-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 133px;
      height: 77px;
      background-color: $bonvoy-white;
      padding: 15px;
    }
    .aloft img.logo {
      max-width: 63px;
    }
    .four-points img.logo {
      max-width: 61px;
    }
    .the-luxury-collection img.logo {
      max-width: 76px;
    }
    .autograph-collection-hotels img.logo {
      max-width: 82px;
    }
    .design-hotels img.logo {
      max-width: 64px;
    }
    .marriott img.logo {
      max-width: 57px;
    }
    .springhill-suites img.logo {
      max-width: 77px;
    }
    .moxy-hotels img.logo {
      max-width: 85px;
    }
    .points-to-miles img.logo {
      max-width: 38px;
    }
    img.logo {
      max-width: 90px;
      max-height: 50px;
      width: auto;
    }
    .swiper-pagination {
      display: flex;
      justify-content: center;
      bottom: -48px;
      height: 15px;
    }
    .nav-wrapper {
      position: absolute;
      bottom: -40px;
      left: 50%;
      transform: translateX(-50%);
      width: 150px;
      z-index: 11;
    }
    .swiper-button-next,
    .swiper-button-prev {
      top: unset;
    }
    .single-redemption {
      .swiper-wrapper {
        display: flex;
        justify-content: center;
      }
      .swiper-slide {
        margin: 0;
      }
    }
    .module-wrap {
      display: flex;
      justify-content: flex-start;
    }
    .module-1 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 0 0 415px;
      padding-right: 20px;
      border-right: 1px solid $color-1;
      .copy-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-left: 20px;
      }
    }
    .module-2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-left: 30px;
      .body {
        font-size: 18px;
      }
    }
    .points-lead-in {
      margin-top: 20px;
      margin-bottom: 0;
      font-size: 18px;
    }
    .points {
      font-size: 38px;
    }

    @media #{$mxw-lg} {
      .swiper-slide {
        max-width: 85%;
      }
    }
    @media #{$mxw-md} {
      .heading {
        font-size: 24px;
      }
      .swiper-slide {
        max-width: unset;
        width: 274px;
        padding: 25px 25px 15px;
      }
      .logo-wrap {
        width: 106px;
        height: 62px;
        padding: 10px;
      }
      img.logo {
        max-width: 90%;
        max-height: 70%;
      }
      .module-wrap {
        flex-direction: column;
      }
      .module-1 {
        flex-direction: column;
        flex: 1 0 auto;
        margin-bottom: 17px;
        padding-right: 0;
        padding-bottom: 12px;
        border-right: none;
        border-bottom: 1px solid $color-1;
        .copy-wrap {
          margin-top: 10px;
          margin-left: 0;
        }
      }
      .module-2 {
        margin-left: 0;
        .body {
          font-size: 14px;
        }
      }
      .points-lead-in {
        margin-top: 14px;
        margin-bottom: 0;
        font-size: 15px;
      }
      .points {
        font-size: 32px;
      }
      .points-label {
        font-size: 14px;
      }
    }
  } // .redemption-highlights
} // #section-highlights

#section-membership {
  .main-content {
    max-width: 1020px;
    margin: 0 auto;
    @media #{$mxw-lg} {
      padding: 0 50px;
    }
    @media #{$mxw-md} {
      flex-direction: column;
      align-items: center;
      padding: 0 10px 0 20px;
    }
  }
  .flex-grid {
    width: 100%;
    @media #{$mnw-lg} {
      @include flex-grid(3, 20px);
    }
    @media #{$mxw-lg} {
      flex-direction: column;
    }
    @media #{$mxw-md} {
      max-width: 450px;
      margin: 0 auto;
    }
  }
  .heading-module {
    max-width: 315px;
    margin-right: 80px;
    @media #{$mxw-lg} {
      margin-right: 50px;
    }
    @media #{$mxw-md} {
      margin-right: 0;
      margin-bottom: 30px;
      text-align: center;
    }
  }
  .module {
    position: relative;
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media #{$mxw-lg} {
      flex-direction: row;
      margin-bottom: 32px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .copy-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1 1 auto;
      p {
        font-family: $font-aldine-light;
        text-align: center;
        max-width: 180px;
      }
      a {
        margin-top: 20px;
      }
      @media #{$mxw-lg} {
        align-items: flex-start;
        margin-left: 20px;
        p {
          text-align: left;
          max-width: unset;
        }
        a {
          margin-top: 8px;
        }
      }
    }
  }
  .circle {
    position: relative;
    width: 50px;
    height: 50px;
    margin-bottom: 26px;
    border: 1px solid $gray-20;
    border-radius: 50%;
    @media #{$mxw-lg} {
      margin-bottom: 0;
    }
  }
}

#section-lifetime-community {
  nav {
    li {
      margin-right: 40px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    .desktop button {
      position: relative;
      color: $bonvoy;
      font-family: $font-main-semibold;
      @include transition;
      @media #{$mxw-xl} {
        font-size: 16px;
      }
      &:hover {
        color: $black;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: transparent;
      }
      &.active {
        color: $black;
        font-family: $font-main-bold;
        &:after {
          background-color: $black;
        }
      }
    }
    .dropdown {
      min-width: 262px;
      .dropdown-toggle {
        font-family: $font-main-semibold;
        font-size: 16px;
        color: $black;
        border-radius: $input-bd-rad;
        box-shadow: $box-shadow-2;
        padding: 6px 12px;
      }
      .dropdown-menu {
        padding: 0 8px 2px;
        button {
          width: 100%;
          font-family: $font-main-semibold;
          color: $bonvoy;
          text-align: left;
          border-bottom: 1px solid $black;
          padding: 5px 0;
          &.active {
            color: $black;
            font-family: $font-main-bold;
          }
        }
        li:last-of-type button {
          border: none;
        }
      }
    }
  }
  .map-rankings-wrap {
    @media #{$mxw-lg} {
      max-width: 600px;
      margin: 0 auto;
    }
    .map {
      width: 840px;
      flex: 1 0 840px;
      @media #{$mxw-xl} {
        width: 65%;
        flex-basis: 65%;
      }
      @media #{$mxw-lg} {
        width: 100%;
        flex-basis: 100%;
        margin-bottom: 20px;
      }
      img {
        border-radius: 17px;
        @media #{$mxw-md} {
          border-radius: 12px;
        }
        @media #{$mxw-sm} {
          border-radius: 6px;
        }
      }
    }
    .swiper-outer-wrap {
      @media #{$mxw-lg} {
        max-width: 400px;
        margin: 0 auto;
      }
      @media #{$mxw-sm} {
        max-width: 100%;
      }
      .swiper {
        padding-top: 80px;
        @media #{$mxw-xl} {
          padding-top: 70px;
        }
      }
      .heading {
        @media #{$mxw-lg} {
          text-align: center;
        }
      }
      .swiper-slide ul {
        max-height: 335px;
        overflow-y: scroll;
        padding-right: 8px;
        @media #{$mxw-xl} {
          max-height: 280px;
        }
        @media #{$mxw-lg} {
          max-height: 290px;
        }
      }
      li {
        padding-bottom: 22px;
        font-size: 16px;
        &:last-of-type {
          padding-bottom: 0;
        }
        @media #{$mxw-xl} {
          padding-bottom: 20px;
        }
        @media #{$mxw-lg} {
          margin-left: 20px;
        }
        .map-location-icon {
          display: flex;
          flex: 0 0 auto;
          width: 27px;
          height: 37px;
          margin-right: 16px;
        }
        .text {
          margin-top: 3px;
        }
      }
    }
    .swiper-pagination {
      top: 10px;
      height: 10px;
      border-bottom: 1px solid $gray;
      padding-bottom: 40px;
      margin-bottom: 30px;
    }
    .swiper-pagination-bullet {
      position: relative;
      bottom: 8px;
    }
    .swiper-button-next,
    .swiper-button-prev {
      top: 20px;
      z-index: 11;
    }
  }

  .moments {
    img {
      border-radius: 28px;
    }
    .desktop {
      @include flex-grid(2, 20px);
      @media #{$mxw-xl} {
        max-width: 900px;
        margin: 0 auto;
      }
      @media #{$mxw-xs} {
        display: none;
      }
    }
    .mobile {
      @media #{$mnw-xs} {
        display: none;
      }
      .img-wrap,
      .wrap {
        max-width: 305px;
      }
    }
    .flex-grid {
      @media #{$mnw-xl} {
        @include flex-grid(2, 20px);
      }
      @media #{$mxw-xl} {
        flex-direction: column;
      }
    }
    .img-wrap .filter {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.5);
      border-radius: 28px;
    }
    .img-wrap .points {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: $font-aldine-light;
      font-size: 50px;
      color: #fff;
      z-index: 9;
      @media #{$mxw-sm} {
        font-size: 30px;
      }
      @media #{$mxw-xs} {
        font-size: 50px;
      }
    }
    .mobile {
      .swiper-slide {
        width: auto;
        margin-right: 20px;
      }
    }
  } // .moments
} // #section-lifetime-community

#section-redemptions {
  position: relative;
  .modules {
    @include flex-grid(3, 20px);
  }
  .module {
    display: flex;
    flex-direction: column;
    color: $black;
    overflow: hidden;
    border: 1px solid $gray-20;
    box-shadow: $box-shadow-2;
    @media #{$mxw-sm} {
      max-width: 305px;
    }
    &:hover {
      box-shadow: $box-shadow-2-hov;
      .link-arrow {
        font-weight: bold;
      }
    }
  }
  .copy-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 20px 30px 30px;
    @media #{$mxw-md} {
      padding: 15px 10px 20px;
    }
    .link-arrow {
      margin-top: auto;
    }
  }
  *:not(.background) {
    position: relative;
    z-index: 9;
  }
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 426px;
    background-color: $bonvoy-white;
    @media #{$mxw-md} {
      height: 363px;
    }
    @media #{$mxw-sm} {
      height: 419px;
    }
    @media #{$mxw-xs} {
      height: 478px;
    }
  }
  .swiper-slide {
    @media #{$mnw-sm} {
      height: unset;
    }
    @media #{$mxw-sm} {
      width: auto;
      margin-right: 20px;
    }
  }
}

.swiper-outer-wrap {
  position: relative;
}

.swiper-boutiques,
.swiper-moments,
.swiper-redemptions,
.swiper-celebrate {
  padding-top: 25px;
  .swiper-pagination {
    top: -20px;
    height: 20px;
  }
}

#section-benefits {
  background-image: url('#{$imgPath}bg_benefits_d.jpg');
  background-color: #333738;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 566px;
  @media #{$mxw-md} {
    background-image: url('#{$imgPath}bg_benefits_m.jpg');
  }
  @media #{$mxw-md} {
    height: 750px;
  }
  @media #{$mxw-sm} {
    height: 835px;
  }
  @media #{$mxw-xxs} {
    height: 880px;
  }
  .wrap-1 {
    color: #fff;
    flex: 0 0 300px;
    @media #{$mxw-md} {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      margin: 60px 0 35px;
      * {
        text-align: center;
      }
    }
  }
  .wrap-2 {
    [class*='heading'] {
      text-align: left;
    }
  }
  #accordion {
    .card {
      position: relative;
      background-color: #fff;
      padding: 18px 50px 22px 15px;
      margin-bottom: 20px;
      width: 551px;
      &:last-of-type {
        margin-bottom: 0;
      }
      .collapse .wrap,
      .collapsing .wrap {
        margin-left: 52px;
      }
      @media #{$mxw-lg} {
        width: auto;
      }
      @media #{$mxw-md} {
        padding: 18px 30px 22px 15px;
      }
    }
    .img-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: $bonvoy-white;
      box-shadow: $box-shadow-2;
    }
    img {
      object-fit: contain;
      max-height: 35px;
      padding: 7px;
    }
    .collapsed {
      img.arrow {
        transform: rotate(0deg);
      }
    }
    img.arrow {
      position: absolute;
      width: 16px;
      top: 30px;
      right: 20px;
      transform: rotate(180deg);
      padding: 0;
      @include transition;
    }
    @media #{$mxw-md} {
      margin-bottom: 60px;
      p {
        font-size: 16px;
      }
    }
    @media #{$mxw-xxs} {
      p {
        font-size: 14px;
      }
    }
  }
}

.anchor-link,
.anchor-link-2 {
  position: relative;
}
.anchor-link {
  bottom: 40px;
}
.anchor-link-2 {
  bottom: 124px;
}

#page-nav {
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  z-index: 99;
  li {
    margin: 0 15px;
    @media #{$mxw-page-nav} {
      margin: 0;
    }
  }
  a {
    position: relative;
    font-family: $font-main-semibold;
    font-size: 16px;
    padding: 17px 0 15px;
    text-transform: uppercase;
    color: $bonvoy;
    letter-spacing: 1.75px;
    text-align: center;
    @media (min-width: 1102px) and (max-width: 1210px) {
      font-size: 14px;
    }
    @media #{$mnw-page-nav} and (max-width: 1101px) {
      font-size: 12px;
    }
    @media #{$mxw-page-nav} {
      width: 100%;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: transparent;
      @include transition;
    }
    &:not(.active):hover {
      @media #{$mnw-page-nav} {
        &:after {
          background-color: $bonvoy;
        }
      }
    }
    &.active {
      font-family: $font-main-semibold;
      color: $black;
      @media #{$mnw-page-nav} {
        &:after {
          background-color: $black;
        }
      }
      @media #{$mxw-page-nav} {
        background-color: $bonvoy-white;
      }
    }
  }
  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: $page-max-width;
  }
  .des-page-nav {
    display: flex;
    justify-content: center;
    list-style: none;
  }
  .mob-page-nav {
    display: none;
  }
  #page-nav-label {
    font-size: 16px;
    letter-spacing: 1.75px;
  }
  @media (max-width: 1024px) {
    background-color: $bonvoy-white;
    .des-page-nav {
      display: none;
    }
    .mob-page-nav {
      display: block;
      .dropdown-toggle {
        padding: 11px 0 17px;
      }
      img.arrow {
        width: 8px;
        height: 5px;
      }
      a {
        padding: 11px 15px 11px;
      }
    }
    .dropdown-menu {
      border: none;
      border-bottom: 1px solid $gray-20;
      border-radius: 0;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    }
  }
}

.section-lead-in {
  text-align: center;
  .heading-caps {
    margin-bottom: 20px;
  }
  .copy {
    font-family: $font-aldine-light;
    font-size: 20px;
    max-width: 580px;
    margin: 0 auto;
    @media #{$mxw-md} {
      font-size: 18px;
    }
    .link-1 {
      font-family: $font-aldine-light;
      font-weight: bold;
    }
  }
}
// SHIMMER ANIMATION
.shimmer {
  display: flex;
  justify-content: center;
}
.platinum .line {
  background-color: $platinum;
}
.titanium .line {
  background-color: $titanium;
}
.line {
  width: 125px;
  height: 3px;
  margin-bottom: 25px;
  background-image: linear-gradient(
    var(--shimmer-angle),
    transparent 0%,
    var(--color-shimmer) 50%,
    transparent 100%
  );
  background-repeat: no-repeat;
  background-position: var(--shimmer-bg-pos);
  animation: var(--shimmer);
  border-radius: 2px;
}
@keyframes shimmer {
  0% {
    // background-position: -100vw;
    background-position: -125px;
  }
  100% {
    // background-position: 100vw;
    background-position: 125px;
  }
}
// [END] SHIMMER ANIMATION
