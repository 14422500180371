$fontsPath: '../fonts/';

@font-face {
  font-family: 'Icons';
  src: url('#{$fontsPath}icons/icons2.eot'); /* IE9 Compat Modes */
  src: url('#{$fontsPath}icons/icons2.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fontsPath}icons/icons2.woff') format('woff'), /* Pretty Modern Browsers */
       url('#{$fontsPath}icons/icons2.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('#{$fontsPath}icons/icons2.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Swiss Regular';
  src: url('#{$fontsPath}swiss/Swiss_Regular/Swiss721BT-Regular.eot');
  src: url('#{$fontsPath}swiss/Swiss_Regular/Swiss721BT-Regular.eot?#iefix') format('embedded-opentype'),
       url('#{$fontsPath}swiss/Swiss_Regular/Swiss721BT-Regular.woff2') format('woff2'),
       url('#{$fontsPath}swiss/Swiss_Regular/Swiss721BT-Regular.woff') format('woff'),
       url('#{$fontsPath}swiss/Swiss_Regular/Swiss721BT-Regular.ttf') format('truetype'),
       url('#{$fontsPath}swiss/Swiss_Regular/Swiss721BT-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Swiss Medium';
  src: url('#{$fontsPath}swiss/Swiss_Medium/Swiss721BT-Medium.eot');
  src: url('#{$fontsPath}swiss/Swiss_Medium/Swiss721BT-Medium.eot?#iefix') format('embedded-opentype'),
       url('#{$fontsPath}swiss/Swiss_Medium/Swiss721BT-Medium.woff2') format('woff2'),
       url('#{$fontsPath}swiss/Swiss_Medium/Swiss721BT-Medium.woff') format('woff'),
       url('#{$fontsPath}swiss/Swiss_Medium/Swiss721BT-Medium.ttf') format('truetype'),
       url('#{$fontsPath}swiss/Swiss_Medium/Swiss721BT-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Swiss Bold';
  src: url('#{$fontsPath}swiss/Swiss_Bold/Swiss721BT-Bold.eot');
  src: url('#{$fontsPath}swiss/Swiss_Bold/Swiss721BT-Bold.eot?#iefix') format('embedded-opentype'),
       url('#{$fontsPath}swiss/Swiss_Bold/Swiss721BT-Bold.woff2') format('woff2'),
       url('#{$fontsPath}swiss/Swiss_Bold/Swiss721BT-Bold.woff') format('woff'),
       url('#{$fontsPath}swiss/Swiss_Bold/Swiss721BT-Bold.ttf') format('truetype'),
       url('#{$fontsPath}swiss/Swiss_Bold/Swiss721BT-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Swiss Light';
  src: url('#{$fontsPath}swiss/Swiss_Light/Swiss721BT-Light.eot');
  src: url('#{$fontsPath}swiss/Swiss_Light/Swiss721BT-Light.eot?#iefix') format('embedded-opentype'),
       url('#{$fontsPath}swiss/Swiss_Light/Swiss721BT-Light.woff2') format('woff2'),
       url('#{$fontsPath}swiss/Swiss_Light/Swiss721BT-Light.woff') format('woff'),
       url('#{$fontsPath}swiss/Swiss_Light/Swiss721BT-Light.ttf') format('truetype'),
       url('#{$fontsPath}swiss/Swiss_Light/Swiss721BT-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Swiss Italic';
  src: url('#{$fontsPath}swiss/Swiss_Italic/Swiss721BT-Italic.eot');
  src: url('#{$fontsPath}swiss/Swiss_Italic/Swiss721BT-Italic.eot?#iefix') format('embedded-opentype'),
       url('#{$fontsPath}swiss/Swiss_Italic/Swiss721BT-Italic.woff2') format('woff2'),
       url('#{$fontsPath}swiss/Swiss_Italic/Swiss721BT-Italic.woff') format('woff'),
       url('#{$fontsPath}swiss/Swiss_Italic/Swiss721BT-Italic.ttf') format('truetype'),
       url('#{$fontsPath}swiss/Swiss_Italic/Swiss721BT-Italic.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Aldine Light';
  src: url('#{$fontsPath}aldine/Aldine_Light/Aldine721BT-Light.eot');
  src: url('#{$fontsPath}aldine/Aldine_Light/Aldine721BT-Light.eot?#iefix') format('embedded-opentype'),
       url('#{$fontsPath}aldine/Aldine_Light/Aldine721BT-Light.woff2') format('woff2'),
       url('#{$fontsPath}aldine/Aldine_Light/Aldine721BT-Light.woff') format('woff'),
       url('#{$fontsPath}aldine/Aldine_Light/Aldine721BT-Light.ttf') format('truetype'),
       url('#{$fontsPath}aldine/Aldine_Light/Aldine721BT-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Aldine Light Italic';
  src: url('#{$fontsPath}aldine/Aldine_Light-Italic/Aldine721BT-Light-Italic.eot');
  src: url('#{$fontsPath}aldine/Aldine_Light-Italic/Aldine721BT-Light-Italic.eot?#iefix') format('embedded-opentype'),
       url('#{$fontsPath}aldine/Aldine_Light-Italic/Aldine721BT-Light-Italic.woff2') format('woff2'),
       url('#{$fontsPath}aldine/Aldine_Light-Italic/Aldine721BT-Light-Italic.woff') format('woff'),
       url('#{$fontsPath}aldine/Aldine_Light-Italic/Aldine721BT-Light-Italic.ttf') format('truetype'),
       url('#{$fontsPath}aldine/Aldine_Light-Italic/Aldine721BT-Light-Italic.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
