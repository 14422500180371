* {
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  @media #{$mxw-sm} {
    max-width: 575px;
  }
}

.heading,
.subheading {
  line-height: 1.2;
  margin-bottom: 15px;
}
.heading {
  width: 100%;
  font-family: $font-heading;
  font-size: 38px;
  @media #{$mxw-md} {
    font-size: 28px;
  }
}
.heading-caps {
  font-family: $font-swiss-medium;
  font-size: 16px;
  letter-spacing: 1.75px;
  text-transform: uppercase;
}
.heading-italic {
  font-family: $font-aldine-italic;
  font-size: 24px;
}
.subheading {
  font-family: $font-subheading;
  font-size: 24px;
  @media #{$mxw-md} {
    font-size: 20px;
  }
}
.subheading-2 {
  font-family: $font-subheading;
  font-size: 20px;
  @media #{$mxw-md} {
    font-size: 18px;
  }
}

.card {
  overflow: hidden;
  color: $black;
  border-radius: $bd-rad-1;
  // Bootstrap reset
  display: block;
  min-width: unset;
  background-color: transparent;
  border: none;
}

.dropdown {
  &.show {
    img.arrow {
      transform: rotate(180deg);
    }
  }
}
.dropdown-toggle {
  background-color: #fff;
  @include transition;
  &:after {
    display: none;
  }
  img.arrow {
    width: 16px;
    @include transition;
  }
}
.dropdown-menu {
  .option * {
    pointer-events: none;
  }
}

.img-container-3-2 {
  position: relative;
  padding-top: 66.66%;
  margin: -1px 0;
  background-color: #fff;
}
.img-thumbnail-3-2 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-outer-wrap {
  overflow: hidden;
}
.swiper {
  overflow: visible;
}
.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid $black;
  opacity: 1;
  &.swiper-pagination-bullet-active {
    background-color: $black;
  }
}
.swiper-button-next,
.swiper-button-prev {
  &:after {
    color: $bonvoy;
    font-size: 18px;
  }
}

// %%%%%%%%%%%%%%%%%%%%%%%
// LINK STYLES
.inline {
  font-family: $font-main-bold;
  color: $bonvoy;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
.link-1 {
  font-family: $font-main-semibold;
  color: $bonvoy;
  @include transition;
  &:hover {
    color: $black;
    text-decoration: underline;
    sup {
      color: $black;
    }
  }
  &.underline {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
.link-2 {
  color: $black;
  &:hover {
    text-decoration: underline;
  }
}
.link-3 {
  color: $bonvoy;
  text-decoration: underline;
  &:hover {
    color: $black;
    text-decoration: none;
  }
}
.link-arrow {
  display: inline-block;
  position: relative;
  margin-left: 20px;
  font-family: $font-swiss-light;
  font-size: 14px;
  color: $black;
  text-transform: uppercase;
  letter-spacing: 0.93px;
  text-align: left;
  @include transition;
  &:before {
    content: url('#{$imgPath}icon_double-caret-right.png');
    position: absolute;
    top: -2px;
    left: -23px;
    transform: scale(0.5);
  }
  &:hover,
  &:focus {
    font-weight: bold;
  }
}
.link-arrow-black {
  @extend .link-arrow;
  &:before {
    content: url('#{$imgPath}icon_double-caret-right_black.png');
  }
}
// [END] LINK STYLES
// %%%%%%%%%%%%%%%%%%%%%%%
// BUTTON STYLES
.btn-1,
.btn-primary {
  display: inline-block;
  background-color: $black;
  color: #fff;
  font-family: $font-btn;
  font-size: 16px;
  border-radius: 22px;
  padding: 11px 42px;
  text-align: center;
  height: auto;
  @include transition;
  &:hover {
    background-color: lighten($black, 20%);
    text-decoration: none;
  }
  &[disabled='true'],
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
    &:hover {
      background-color: $black;
    }
  }
}
.btn-2,
.btn-secondary,
.btn-primary-inverse {
  @extend .btn-1;
  background-color: #fff;
  color: $black;
  &:hover {
    background-color: darken(#fff, 25%);
  }
}
.btn-2-bd {
  @extend .btn-2;
  border: $bd-black;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none;
}
.btn-sm {
  padding: 2px 14px 3px;
}
// [END] BUTTON STYLES
// %%%%%%%%%%%%%%%%%%%%%%%

[class*='icon-'] {
  position: relative;
  margin-right: 20px;
  &:before {
    font-family: 'Icons';
    position: absolute;
    left: 0;
    top: 0;
  }
}
.icon-error:before {
  content: '\e923';
  color: $color-error;
}
.icon-success:before {
  content: '\e900';
  color: $color-success;
}
.input.error {
  border-color: $color-error;
}

.errors {
  position: relative;
  background-color: $color-error-lt;
  padding: 20px 50px 20px 20px;
  max-width: 380px;
  @media #{$mxw-md} {
    max-width: unset;
  }
  p,
  li {
    font-family: $font-main-semibold;
    font-size: 14px;
    color: $color-error;
    padding-bottom: 10px;
    &:last-of-type {
      padding-bottom: 0;
    }
  }
  .icon-error {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

.modal {
  .modal-dialog {
    @media #{$mnw-sm} {
      width: 720px;
      max-width: 95vw;
    }
  }
  .modal-content {
    border-radius: 20px;
  }
  .modal-header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
  }
  .modal-body {
    padding: 30px;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 26px;
    opacity: 1;
    z-index: 9;
    &:hover {
      opacity: 0.75;
    }
  }
}

.circled-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--circled-number-size);
  min-width: var(--circled-number-size);
  height: var(--circled-number-size);
  min-height: var(--circled-number-size);
  font-family: $font-aldine-light;
  font-size: 16px;
  color: $black;
  border: 1px solid $color-1;
  border-radius: 50%;
  margin-right: 16px;
}

.bg-line {
  position: relative;
  display: flex;
  padding: 0 20px;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: $color-1;
    height: 1px;
    width: 100%;
  }
  > * {
    position: relative;
    display: inline-block;
    padding: 0 5px;
    background-color: #fff;
    z-index: 9;
  }
}

// https://codepen.io/mondal10/pen/WNNEvjV
.flipping-circle {
  width: var(--flip-circle-size);
  height: var(--flip-circle-size);
  perspective: 600px;
  .wrap {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.5s;
    * {
      pointer-events: none;
    }
    &.flip {
      transform: rotateY(-180deg);
    }
  }
  .face {
    position: absolute;
    top: 0;
    left: 0;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: var(--flip-circle-size);
    height: var(--flip-circle-size);
    border-radius: 50%;
    box-shadow: $box-shadow-2;
  }
  .face-back {
    transform: rotateY(180deg);
  }
  @media #{$mxw-xxs} {
    --flip-circle-size: 65px;
  }
} // .flipping-circle

.loading-spinner {
  position: relative;
  display: none;
  width: 40px;
  height: 40px;
  &.show {
    display: block;
  }
}

.banner {
  background-color: $coral-lt;
  margin: 30px auto -30px;
  padding: 20px 55px;
  border-radius: 16px;
  @media #{$mxw-sm} {
    margin: 20px auto 30px;
  }
}

p {
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
}
button {
  color: $black;
}
a {
  cursor: pointer;
  text-decoration: none;
  @include transition;
  &:hover {
    text-decoration: none;
  }
}
img {
  width: 100%;
}
ul.decimal {
  list-style: decimal;
}
ul.roman {
  list-style: lower-roman;
}
ul.lower-alpha {
  list-style: lower-alpha;
}
ul.list-style-none {
  list-style: none;
}
ul ul {
  margin-top: 15px;
}
ul,
ol {
  margin-left: 40px;
}
li {
  padding-bottom: 6px;
  &:last-of-type { margin-bottom: 0; }
}
hr {
  border-top: 1px solid #979797;
}
button {
  background-color: transparent;
}
sup {
  top: -0.9em;
  font-size: 50%;
}

.input-wrap {
  &.text {
    display: flex;
    flex-direction: column;
  }
  &.radio {
    display: flex;
    align-items: center;
    input {
      width: $radio-btn-size;
      flex: 0 0 $radio-btn-size;
    }
    label {
      margin: 0;
    }
  }
  &.checkbox {
    display: flex;
    align-items: flex-start;
  }
}

input[type='text'],
input[type='email'],
input[type='tel'],
select {
  width: 100%;
  padding: 10px;
  border: 1px solid $black;
  border-radius: $input-bd-rad;
  height: 44px;
}
select {
  background: url('#{$imgPath}icon_caret-down.png') no-repeat 95% 50%;
  background-color: #fff;
  background-size: 8px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
input[type='checkbox'] {
  margin: 2px 8px 0 0;
}
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid $black;
  border-radius: $input-bd-rad;
}

.error-msg {
  display: none;
}
.error {
  position: relative;
  input,
  select {
    border-color: $color-error;
    &:focus,
    &:focus-visible {
      outline: 1px solid $color-error;
    }
  }
  .error-msg {
    display: block;
    position: absolute;
    margin-top: 2px;
    font-size: 12px;
    line-height: 1.15;
    font-family: $font-swiss-italic;
    color: $color-error;
  }
}

// CUSTOM RADIO BUTTONS
input[type='radio'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0 10px 0 0;
  font: inherit;
  color: $radio-btn-color;
  width: $radio-btn-size;
  height: $radio-btn-size;
  flex: 1 0 auto;
  border: 1px solid $radio-btn-color;
  border-radius: 50%;
  display: grid;
  place-content: center;
  &:before {
    content: '';
    width: $radio-btn-size;
    height: $radio-btn-size;
    border-radius: 50%;
    transform: scale(0);
    box-shadow: inset 1em 1em $radio-btn-color;
    background-color: CanvasText; // Windows High Contrast Mode
  }
  &:checked:before {
    transform: scale(1);
  }
}
// END CUSTOM RADIO BUTTONS

// CUSTOM CHECKBOX
 .input-wrap.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.input-wrap.checkbox input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid $black;
  border-radius: 3px;
}

/* When the checkbox is checked, add a blue background */
.input-wrap.checkbox input:checked ~ .checkmark {
  background-color: $black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.input-wrap.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.input-wrap.checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
// END CUSTOM CHECKBOX

[class*='icon-'] {
  position: relative;
  &:before {
    font-family: 'Icons';
    position: absolute;
    left: 0;
    top: 0;
  }
}
.icon-error:before {
  content: '\e923';
  color: $color-error;
}
